import Home from "../pages/Home";
import About from "../pages/About";
import Resume from "../pages/Resume";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";

const routesConfig = [
  {
    path: "/",
    element: <Home />,
    title: "Sojol Rana",
    description: "Welcome to the portfolio and blog of Sojol Rana. Explore projects, blogs, and more.",
    canonical: "https://sojolrana.com/",
  },
  {
    path: "/about",
    element: <About />,
    title: "About | Sojol Rana",
    description: "Learn about Sojol Rana, a passionate developer and AI enthusiast.",
    canonical: "https://sojolrana.com/about",
  },
  {
    path: "/resume",
    element: <Resume />,
    title: "Resume | Sojol Rana",
    description: "View the resume of Sojol Rana, highlighting skills, experiences, and achievements.",
    canonical: "https://sojolrana.com/resume",
  },
  {
    path: "/contact",
    element: <Contact />,
    title: "Contact | Sojol Rana",
    description: "Get in touch with Sojol Rana via the contact page.",
    canonical: "https://sojolrana.com/contact",
  },
  {
    path: "*", // Matches all undefined routes
    element: <NotFound />,
    title: "Page not found! | Sojol Rana",
    description: "The page you are looking for does not exist. Please check the URL or return to the homepage.",
    canonical: "https://sojolrana.com/",
  },
];

export default routesConfig;
