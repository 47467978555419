import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Recaptcha from 'react-google-recaptcha';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(''); // Clear previous messages
    setLoading(true); // Start loading when submit is clicked

    if (!captchaValue) {
      setMessage("Please complete the CAPTCHA.");
      setLoading(false);
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
      formData, 
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setMessage('Message sent successfully!');
        setLoading(false); // Stop loading after success
      })
      .catch((err) => {
        console.error('Failed to send email:', err);
        setMessage(`Error sending message. Details: ${err.text}`);
        setLoading(false); // Stop loading after error
      });
  };

  return (
    <div className="contact-page">
      <h2>Contact Me</h2>
      <p className="contact-intro">
        I’d love to hear from you! Whether you have a question, a project idea, or just want to connect, feel free to reach out.
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="from_name"
            value={formData.from_name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="from_email"
            value={formData.from_email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Message:
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </label>

        <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleCaptchaChange}
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        
        {/* Display message */}
        {message && <p className={`form-message ${loading ? 'warning' : 'success'}`}>{message}</p>}
      </form>
    </div>
  );
}

export default Contact;